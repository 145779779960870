import React, { useState } from "react";
import { errorToast, successToast } from '../Constant/functions';
import './Home.css';

const Home = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    function contactReset() {
        setName('');
        setEmail('');
        setPhone('');
        setSubject('');
        setMessage('');
    }

    const onSubmit = () => {
        if (name === '') {
            errorToast('Name is required !');
            return false;
        }
        else if (email === '') {
            errorToast('Email is required !');
            return false;
        }
        else if (phone === '') {
            errorToast('Phone number is required !');
            return false;
        }
        else if (subject === '') {
            errorToast('Subject is required !');
            return false;
        }
        else if (message === '') {
            errorToast('Message is required !');
            return false;
        }

        if (name !== '' && email !== '' && phone !== '' && subject !== '' && message !== '') {
            successToast("Message sent successfully, we'll get back to you as soon as possible.");
            contactReset();
        }
        
    }
    return (
        <div>

            <a className="navbar-brand" href="/">
                <img src="_images/logo.jpeg" className="logo" alt="Openstat Tchad" />
            </a>

            <section id="enquiries">
                <div className="row">
                    <h1>ENQUIRIES</h1>
                    <h2>HAVE A QUESTION? JUST SPIT IT OUT!</h2>

                    <div className="col-lg-offset-3 col-lg-6">

                        <form action="#" method="post" className="contactForm">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input className="field" type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name *" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input className="field" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email *" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input className="field" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone *" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input className="field" type="text" value={subject} onChange={(e) => setSubject(e.target.value)} placeholder="Subject *" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <textarea className="textarea" rows="4" cols="1" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Message *"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <button type="button" onClick={onSubmit} className="sendForm" id="submit">
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div id="status"></div>
                        </form>
                        <div className="clear"></div>

                    </div>
                </div>
            </section>

            <section id="social" >
                <div className="row">
                    <h1>FOLLOW US</h1>
                    <div className="col-lg-offset-3 col-lg-6">
                        <ul className="social-icons">
                            <li className="facebook"><a rel="noopener noreferrer" href="https://web.facebook.com/profile.php?id=61552821295901" target="_blank"><i className="genericon genericon-facebook"></i></a></li>
                            <li className="twitter"><a rel="noopener noreferrer" href="https://twitter.com/BetMeziz/status/1727216889515118756" target="_blank"><i className="genericon genericon-twitter"></i></a></li>
                            <li className="instagram"><a rel="noopener noreferrer" href="https://www.instagram.com/meziz_bet/" target="_blank"><i className="genericon genericon-flickr"></i></a></li>
                        </ul>
                    </div>
                </div>
            </section>

            <section id="contact">

                <div className="row">
                    <h1>OUR OFFICE</h1>
                    <address>
                        <br />Mvuli Road<br />Nairobi county<br />Kenya<br /><br /><strong>+254 740 842421</strong><br />
                        <a href="mailto:#" className="uppercase">info@mezizbet.com</a>
                    </address>
                </div>
            </section>


            {/* <section id="gmap" data-location="Česká, Brno, Czech Republic" title="myFrame">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2606.7312667459055!2d16.5972769!3d49.2056573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47129441b1e27811%3A0xc85514efdd9ee0d9!2sKounicova%2C%20602%2000%20Brno%2C%20Czechia!5e0!3m2!1sen!2sid!4v1693837050059!5m2!1sen!2sid" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </section> */}

        </div>



    )
}

export default Home