import React from 'react';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import Home from './Components/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


function App() {
    return (
        <div className="App">
            <aside id="container">
                <Header />
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path={`/*`} element={<Home />} />
                    </Routes>
                </BrowserRouter>
                <Footer />
            </aside>
        </div>
    );
}

export default App;
