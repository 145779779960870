import Swal from 'sweetalert2'



export const successToast = (message)=>{
    Swal.fire({
        position: "top-end",
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 4500
    });
}

export const errorToast = (message)=>{
    Swal.fire({
        title: 'Error!',
        text: message,
        icon: 'error',
        confirmButtonText: 'Ok'
    })
}