import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <div>
                <footer>
                &copy; Meziz Bet 2023 © All Rights Reserved
                </footer>
                {/* #76287D */}
                {/* #FEF852 */}
            </div>
        )
    }
}

export default Footer 