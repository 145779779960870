import React, { Component } from 'react'

export class Header extends Component {
    render() {
        return (
            <div>

                <div id="down"><a href="#enquiries" className="scroller"><img src="_images/blank.png" className="down" alt="" /></a></div>


                <section id="content">
                    <div className="row">

                        <div className="col-lg-offset-3 col-lg-6">
                            <h1>Merry Christmas!</h1>
                            <p>Sorry but there is no one here at the moment. We will be back very soon!</p>

                            <div id="defaultCountdown"></div>
                        </div>

                    </div>


                    <div className="parallax-viewport" id="parallax">
                        <div className="parallax-layer layer1">
                            <img src="_images/mountain.png" alt="" />
                        </div>
                        <div className="parallax-layer layer2">
                            <img src="_images/children.png" alt="" className="image2" />
                        </div>
                        <div className="parallax-layer layer3">
                            <img src="_images/elf.png" alt="" className="image3" />
                        </div>
                        <div className="parallax-layer layer4">
                            <img src="_images/santa.png" alt="" className="image4" />
                        </div>
                        <div className="parallax-layer layer5">
                            <img src="_images/elf2.png" alt="" className="image5" />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Header 